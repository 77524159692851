import React from 'react'

// Components
import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

function TermsPage() {
  return (
    <Layout pageName="terms">
      <Nav />
      <div className="content download">
        <h1>Download Bizy</h1>
        <p className="subheader">
          Meetings Without The Meeting &trade;
        </p>
        <div className="last-revision">
          Get Bizy on all your devices
        </div>

        <div className="section">
          <h4>Bizy Desktop for Mac</h4>
          <p>Supports High Sierra (10.13) and above.</p>
          <a className="btn secondary medium" href="https://bizy-desktop.s3.amazonaws.com/Bizy.dmg" download>
            Download for Mac
          </a>
          <p>&nbsp;</p>
          <h4>Bizy Desktop for Windows</h4>
          <a className="btn secondary medium" href="https://bizy-desktop.s3.amazonaws.com/Bizy.exe" download>
            Download for Windows
          </a>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default TermsPage
